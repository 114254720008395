<template>
  <aside
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3
           fixed-start ms-3 bg-gradient-dark"
  >
    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link
        class="sidenav-organization m-0 navbar-brand"
        :title="currentOrganization.name"
        :to="{ name: 'account-organisations' }"
      >
        <img
          :src="currentOrganization.logoUrl"
          class="sidenav-organization__image navbar-brand-img h-100"
          :alt="`${currentOrganization.name} logo`"
        />
        <span class="sidenav-organization__text ms-2 font-weight-bold text-white">
          {{ currentOrganization.name }}
        </span>
      </router-link>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <sidenav-list />
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import SidenavList from "@/components/Layout/Sidenav/SidenavList.vue";

export default {
  name: "SidenavIndex",

  components: {
    SidenavList,
  },

  computed: {
    ...mapGetters('account', [
      'currentOrganization',
    ]),
  },
};
</script>

<style scoped lang="scss">
.sidenav {

  &-organization {
    display: flex !important;
    align-items: center;

    &__image {
      aspect-ratio: 1;
      object-fit: contain;
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
