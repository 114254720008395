export default {
  state: {
    actions: [],
  },

  actions: {
    addAction({ commit }, payload) {
      commit('addAction', payload);
    },

    clearActions({ commit }) {
      commit('clearActions');
    },
  },

  mutations: {
    addAction(state, payload) {
      state.actions.push(payload);
    },

    clearActions(state) {
      state.actions = [];
    },
  },

  namespaced: true,
};
