export const isOffice = () => (
  new Promise((resolve, reject) => {
    fetch('https://api.ipify.org')
      .then((response) => {
        if (!response.ok) {
          return reject();
        }

        return response.text();
      })
      .then((ip) => resolve(ip === process.env.VUE_APP_OFFICE_IP))
      .catch(() => reject())
  })
);
