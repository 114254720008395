<template>
  <material-snackbar
    v-if="instances.length"
    v-bind="attributes"
    class="position-fixed bottom-0 end-0 m-3 fadeIn1 fadeInBottom z-index-1"
    :close-handler="close"
  />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MaterialSnackbar from '@/components/MaterialSnackbar.vue';

export default {
  name: 'AppNotifications',

  components: {
    MaterialSnackbar,
  },

  data: () => ({
    visibilityTimer: null,
  }),

  computed: {
    ...mapState('notification', [
      'instances',
    ]),

    ...mapGetters('notification', [
      'firstInstance',
    ]),

    attributes() {
      const mergedSettings = {
        ...{
          color: 'info',
          date: `1/${this.instances.length}`,
          title: this.$route.meta.displayName,
        },
        ...this.firstInstance,
      };

      mergedSettings.icon.color = mergedSettings.icon.color || 'white';
      return mergedSettings;
    },
  },

  watch: {
    instances: {
      deep: true,
      handler(value) {
        clearTimeout(this.visibilityTimer);

        if (!value.length) {
          return;
        }

        this.visibilityTimer = setTimeout(this.close, 10000);
      },
    },
  },

  methods: {
    ...mapActions('notification', [
      'close',
    ]),
  },
};
</script>
