import { createRouter, createWebHistory, RouterView } from "vue-router";
import { isAuthenticated, isLoggedIn, isNotLoggedIn } from './middlewares/account';
import { isOffice } from './middlewares/ip';
import { root } from './middlewares/redirect';
import store from '@/store';

const routes = [
  {
    path: '/',
    redirect: root,
  },
  {
    path: '/account',
    component: () => import('@/views/shared/account/Layout.vue'),
    children: [
      {
        path: 'login',
        component: () => import('@/views/shared/account/CenteredContent.vue'),
        props: {
          title: 'Inloggen',
        },
        beforeEnter: [isNotLoggedIn],
        children: [
          {
            path: '',
            name: 'account-login',
            component: () => import('@/views/account/login/Index.vue'),
          },
          {
            path: ':username',
            name: 'account-login-password',
            component: () => import('@/views/account/login/Password.vue'),
          },
        ],
      },
      {
        path: 'organisations',
        name: 'account-organisations',
        component: () => import('@/views/account/organisations/Index.vue'),
        beforeEnter: [isAuthenticated],
      },
    ],
    props: {
      hideNavigation: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/Index.vue'),
    props: {
      hideNavigation: true
    },
    meta: {
      displayName: 'Registreren'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Index.vue'),
    beforeEnter: [isLoggedIn],
    meta: {
      displayName: 'Dashboard',
    },
  },
  {
    path: '/app-users',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'app-users',
        component: () => import('@/views/app-users/Index.vue'),
        meta: {
          displayName: 'App-gebruikers',
        },
      },
      {
        path: 'new',
        name: 'new-app-user',
        component: () => import('@/views/app-users/Edit.vue'),
        meta: {
          displayName: 'Nieuwe app-gebruiker',
        },
      },
      {
        path: ':id',
        name: 'edit-app-user',
        component: () => import('@/views/app-users/Edit.vue'),
        meta: {
          displayName: 'App-gebruiker bewerken',
        },
      },
    ],
  },
  {
    path: '/events',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'events',
        component: () => import('@/views/events/Index.vue'),
        meta: {
          displayName: 'Evenementen',
        },
      },
      {
        path: 'new',
        name: 'new-event',
        component: () => import('@/views/events/Edit.vue'),
        meta: {
          displayName: 'Nieuw evenement',
        },
      },
      {
        path: ':id',
        name: 'edit-event',
        component: () => import('@/views/events/Edit.vue'),
        meta: {
          displayName: 'Evenement bewerken',
        },
      },
    ],
  },
  {
    path: '/admins',
    name: 'admins',
    component: () => import('@/views/admins/Index.vue'),
    beforeEnter: [isLoggedIn],
    meta: {
      displayName: 'Beheerders',
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/roles/Index.vue'),
    beforeEnter: [isLoggedIn],
    meta: {
      displayName: 'Rollen & Rechten',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import("@/views/faq/Index.vue"),
    meta: {
      displayName: 'FAQ'
    }
  },
  {
    path: '/import',
    name: 'import',
    component: () => import('@/views/import/Index.vue'),
    beforeEnter: [isOffice],
    meta: {
      displayName: 'Importeren',
    },
  },
  {
    path: '/app',
    component: () => import('@/views/shared/account/Layout.vue'),
    props: {
      hideNavigation: true,
    },
    children: [
      {
        path: 'password',
        component: () => import('@/views/shared/account/CenteredContent.vue'),
        props: {
          title: 'Wachtwoord aanpassen',
        },
        children: [
          {
            path: ':type/:token',
            name: 'app-password',
            component: () => import('@/views/app/password/Index.vue'),
          },
          {
            path: 'success',
            name: 'app-password-success',
            component: () => import('@/views/app/password/Success.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/news',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('@/views/news/Index.vue'),
        meta: {
          displayName: 'Nieuws'
        }
      },
      {
        path: 'new',
        name: 'new-newsitem',
        component: () => import('@/views/news/Edit.vue'),
        meta: {
          displayName: 'Nieuw nieuwsbericht',
        },
      },
      {
        path: ':id',
        name: 'edit-newsitem',
        component: () => import('@/views/news/Edit.vue'),
        meta: {
          displayName: 'Nieuwsbericht bewerken',
        },
      },
    ]
  },
  {
    path: '/club-actions',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'sale',
        component: () => import('@/views/sale/Index.vue'),
        meta: {
          displayName: 'Clubacties'
        }
      },
      {
        path: 'new',
        name: 'new-club-action',
        component: () => import('@/views/sale/Edit.vue'),
        meta: {
          displayName: 'Nieuwe clubactie',
        },
      },
      {
        path: ':id',
        name: 'edit-club-action',
        component: () => import('@/views/sale/Edit.vue'),
        meta: {
          displayName: 'Clubactie bewerken',
        },
      },
    ]
  },
  {
    path: '/groups',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'projects',
        meta: { displayName: 'Groepen' },
        component: () => import('@/views/projects/Index.vue')
      },
      {
        path: 'edit/:projectId',
        name: 'edit-project',
        meta: { displayName: 'Groep aanpassen' },
        component: () => import('@/views/projects/Edit.vue')
      },
      {
        path: 'new',
        name: 'new-project',
        meta: { name: 'Nieuwe groep' },
        component: () => import('@/views/projects/Edit.vue')
      }
    ]
  },
  {
    path: '/group-overview',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: ':projectId',
        name: 'project-overview',
        meta: { displayName: 'Groepsberichten' },
        component: () => import('@/views/project-overview/Index')
      },
      {
        path: 'edit/:projectItemId',
        name: 'edit-project-item',
        meta: { displayName: 'Groepsberict aanpassen' },
        component: () => import('@/views/project-overview/Edit')
      },
      {
        path: 'new/:projectId',
        name: 'new-project-item',
        meta: { name: 'Nieuw groepsbericht' },
        component: () => import('@/views/project-overview/Edit')
      }
    ]
  },
  {
    path: '/company',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'companies',
        component: () => import('@/views/company/Index.vue'),
        meta: {
          displayName: 'Bedrijven',
        },
      },
      {
        path: 'new',
        name: 'new-company',
        component: () => import('@/views/company/Edit.vue'),
        meta: {
          displayName: 'Nieuw bedrijf',
        },
      },
      {
        path: ':id',
        name: 'edit-company',
        component: () => import('@/views/company/Edit.vue'),
        meta: {
          displayName: 'Bedrijf bewerken',
        },
      },
    ],
  },
  {
    path: '/club',
    component: RouterView,
    beforeEnter: [isLoggedIn],
    children: [
      {
        path: '',
        name: 'clubs',
        component: () => import('@/views/club/Index.vue'),
        meta: {
          displayName: 'Clubs',
        },
      },
      {
        path: 'new',
        name: 'new-club',
        component: () => import('@/views/club/Edit.vue'),
        meta: {
          displayName: 'Nieuwe club',
        },
      },
      {
        path: ':id',
        name: 'edit-club',
        component: () => import('@/views/club/Edit.vue'),
        meta: {
          displayName: 'Club bewerken',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeResolve(() => {
  store.dispatch('appHeader/clearActions');
});

export default router;
