<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-12 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted text-lg-end"
            @click="displayCount++"
          >
            © {{ new Date().getFullYear() }}
            <a
              href="https://www.s5.nl/"
              class="font-weight-bold"
              target="_blank"
            >
              S5 MERKwaardige Specialisten
            </a>
            <i
              v-show="displayCount >= 10"
              class="fa fa-user-astronaut"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",

  data: () => ({
    displayCount: 0,
  }),
};
</script>
