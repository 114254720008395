export default {
  state: {
    instances: [],
  },

  getters: {
    firstInstance: (state) => state.instances[0],
  },

  actions: {
    add({ commit }, payload) {
      commit('add', payload);
    },

    addError({ dispatch }, payload) {
      dispatch('add', {
        description: payload,
        icon: {
          component: 'error',
        },
      });
    },

    close({ commit }) {
      commit('close');
    },
  },

  mutations: {
    add(state, payload) {
      state.instances.push(payload);
    },

    close(state) {
      state.instances.shift();
    },
  },

  namespaced: true,
};
