<template>
  <div class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100">
    <ul class="navbar-nav overflow-scroll">
      <li
        v-for="(item, i) in defaultItems"
        :key="i"
        class="nav-item" :class="getRoute() === item.link.substring(1) ? 'active' : ''"
      >
        <sidenav-collapse
          :nav-text="item.translatedName || item.name"
          :collapse="false"
          :aria-controls="''"
          :collapse-ref="item.link"
        >
          <template #icon>
            <i 
              v-if="item.icon != undefined"
              class="material-icons-round opacity-10"
            >
              {{item.icon}}
            </i>
          </template>
        </sidenav-collapse>
      </li>
      
      <hr />

      <li
        v-for="(item, i) in activeModules"
        :key="i"
        class="nav-item" :class="getRoute() === item.link.substring(1) ? 'active' : ''"
      >
        <sidenav-collapse
          :nav-text="item.translatedName || item.name"
          :collapse="false"
          :aria-controls="''"
          :collapse-ref="item.link"
        >
          <template #icon>
            <i 
              v-if="item.icon != undefined"
              class="material-icons-round opacity-10"
            >
              {{item.icon}}
            </i>

            <div
              v-if="item.icon === undefined && item.fontaweomeIcon !== undefined"
              class="fa-icon-wrapper"
            >
              <i 
                :class="`fa fa-solid fa-${item.fontaweomeIcon}`"
              ></i>
            </div>
          </template>
        </sidenav-collapse>
      </li>

      <template v-if="showOfficeMenu">
        <hr class="horizontal light mt-2 mb-2" />

        <li class="nav-item">
          <sidenav-collapse
            nav-text="Importeren"
            :collapse="false"
            :aria-controls="''"
            collapse-ref="/import"
          >
            <template #icon>
              <i class="material-icons-round opacity-10">
                import_export
              </i>
            </template>
          </sidenav-collapse>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { isOffice } from '@/helpers/ip';
import SidenavCollapse from "@/components/Layout/Sidenav/SidenavCollapse.vue";

import { getAllActive } from '@/api/providers/modules'

export default {
  name: "SidenavList",

  components: {
    SidenavCollapse,
  },

  data: () => ({
    items: [],

    defaultItems: [
      {
        icon: 'dashboard',
        link: '/dashboard',
        name: 'Dashboard',
      },
      {
        icon: 'recent_actors',
        link: '/app-users',
        name: 'App-gebruikers',
      },
      {
        icon: 'groups',
        link: '/admins',
        name: 'Admins',
        translatedName: 'Admins',
      },
      {
        icon: 'lock_person',
        link: '/roles',
        name: 'roles',
        translatedName: 'Rollen & Rechten',
      }
    ],

    modules: [
      {
        icon: 'event',
        link: '/events',
        name: 'Events',
        translatedName: 'Evenementen',
      },
      {
        icon: 'newspaper',
        link: '/news',
        name: 'News',
        translatedName: 'Nieuws',
      },
      {
        icon: 'construction',
        link: '/groups',
        name: 'Projects',
        translatedName: 'Groepen',
      },
      {
        icon: 'apartment',
        link: '/company',
        name: 'CompanyPage',
        translatedName: 'Bedrijven'
      },
      {
        icon: 'shield',
        link: '/club',
        name: 'ClubPage',
        translatedName: 'Clubs'
      },
      {
        icon: 'groups',
        link: '/admins',
        name: 'Admins',
        translatedName: 'Admins',
      },
      {
        icon: 'lock_person',
        link: '/roles',
        name: 'roles',
        translatedName: 'Rollen & Rechten',
      },
      {
        icon: 'help',
        link: '/faq',
        name: 'Faq',
        translatedName: 'Veelgestelde vragen'
      },
      { 
        fontaweomeIcon: 'gift',
        link: '/club-actions',
        name: 'Sale',
        translatedName: 'Clubacties'
      }
    ],
    activeModules: [],
    showOfficeMenu: false,
  }),

  created() {
    isOffice()
      .then((showOfficeMenu) => {
        this.showOfficeMenu = showOfficeMenu;
      });
  },

  mounted() {
    this.getModules()
  },

  methods: {
    getModules() {
      getAllActive()
        .then(activeModules => {           
          const moduleNames = activeModules
            .filter(m => m.defaultIsActive || m.isActive)
            .map(m => m.name)

          
          this.activeModules = this.modules
            .filter(m => moduleNames.includes(m.name))
            .sort((a, b) => a.translatedName > b.translatedName)

          this.items = this.defaultItems.concat(this.activeModules);
        })
    },

    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>

<style>
.fa-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.fa { font-size: 20px; }
</style>