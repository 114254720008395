<template>
  <nav
    id="navbarBlur"
    class="app-header shadow-none navbar navbar-main navbar-expand-lg border-radius-xl mt-4"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-4 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :color="color" />
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
      >
        <ul class="navbar-nav justify-content-end ms-md-auto me-n2">
          <li
            v-for="(action, i) in actions"
            :key="i"
            class="nav-item dropdown d-flex align-items-center"
          >
            <button
              type="button"
              class="btn bg-gradient-success mx-2 my-0"
              @click="action.handler"
            >
              <i class="material-icons text-white position-relative text-md pe-2">
                {{ action.icon }}
              </i>
              {{ action.text }}
            </button>
          </li>
          <li class="nav-item dropdown d-flex align-items-center ms-3">
            <button
              id="dropdownMenuButton"
              class="p-2 pb-1 nav-link lh-1 border-0"
              :class="[color]"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="material-icons cursor-pointer">
                account_circle
              </i>
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end me-sm-n4"
              aria-labelledby="dropdownMenuButton"
            >
              <li>
                <a
                  class="dropdown-item"
                  href="javascript:;"
                  @click="logout"
                >
                  <div class="py-1 d-flex">
                    <i class="material-icons me-2">
                      logout
                    </i>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm font-weight-normal">
                        Uitloggen
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import { logout } from '@/helpers/account';
import Breadcrumbs from "@/components/Layout/AppHeader/Breadcrumbs.vue";

export default {
  name: "AppHeader",

  components: {
    Breadcrumbs,
  },

  props: {
    minNav: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: "text-body",
    },
  },

  computed: {
    ...mapState('appHeader', [
      'actions',
    ]),

    currentRouteName() {
      return this.$route.meta.displayName || '';
    },
  },

  created() {
    this.minNav;
  },

  methods: {
    logout,
  },
};
</script>

<style scoped lang="scss">
.app-header {
  position: relative;
  z-index: 1021;
}
</style>
