import { createStore } from "vuex";
import account from './modules/account';
import appHeader from './modules/app-header';
import notification from './modules/notification';
import organization from './modules/organization';

export default createStore({
  modules: {
    account,
    appHeader,
    notification,
    organization,
  },
});
