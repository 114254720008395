<template>
  <sidenav v-if="showNavigation" />
  <main class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
    <app-header
      v-if="showNavigation"
      color=""
    />
    <div
      v-if="showNavigation"
      class="py-2 container-fluid"
    >
      <div class="row">
        <router-view />
      </div>
    </div>
    <router-view v-else />
    <app-footer v-if="showNavigation" />
    <app-notifications />
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getAllActive as getAllActiveModules } from '@/api/providers/modules';
import AppFooter from '@/components/Layout/AppFooter/Index.vue';
import AppHeader from '@/components/Layout/AppHeader/Index.vue';
import AppNotifications from '@/components/Layout/AppNotifications/Index.vue';
import Sidenav from '@/components/Layout/Sidenav/Index.vue';

export default {
  name: "App",

  components: {
    AppFooter,
    AppHeader,
    AppNotifications,
    Sidenav,
  },

  computed: {
    ...mapGetters('account', [
      'currentOrganization',
      'isLoggedIn',
    ]),

    childProps() {
      const { matched } = this.$route;

      if (!matched.length) {
        return;
      }

      return Object.assign({},
        ...this.$route.matched
          .filter((match) => match.props.default)
          .map((match) => match.props.default)
      );
    },

    showNavigation() {
      if (this.childProps === undefined) {
        return false;
      }

      return !this.childProps.hideNavigation;
    }
  },

  watch: {
    currentOrganization: {
      handler() {
        this.loadModules();
      },
      immediate: true,
    },
  },

  beforeMount() {
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  },

  methods: {
    ...mapActions('organization', [
      'setModules',
    ]),

    loadModules() {
      if (!this.isLoggedIn) {
        return;
      }

      getAllActiveModules()
        .then(this.setModules);
    },
  },
};
</script>

<style scoped lang="scss">
  .main-content {
    scroll-behavior: smooth;
  }
</style>
